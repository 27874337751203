<template>
  <svg
    width="50"
    height="51"
    viewBox="0 0 50 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 50.1269C38.8071 50.1269 50 38.9329 50 25.1244C50 11.3159 38.8071 0.121826 25 0.121826C11.1929 0.121826 0 11.3159 0 25.1244C0 38.9329 11.1929 50.1269 25 50.1269Z"
      fill="#EFE6DD"
    />
    <path
      d="M19.55 37.0629C30.6374 37.0629 36.6999 27.8745 36.6999 19.9112C36.6999 19.6487 36.6999 19.3862 36.6874 19.1361C37.8624 18.286 38.8874 17.2234 39.6999 16.0108C38.6249 16.4859 37.4624 16.8109 36.2374 16.9609C37.4874 16.2108 38.4374 15.0357 38.8874 13.6231C37.7249 14.3107 36.4374 14.8107 35.0624 15.0857C33.9624 13.9106 32.3999 13.1855 30.6624 13.1855C27.3375 13.1855 24.6375 15.8858 24.6375 19.2111C24.6375 19.6862 24.6875 20.1487 24.8 20.5863C19.7875 20.3362 15.35 17.936 12.375 14.2857C11.8625 15.1732 11.5625 16.2108 11.5625 17.311C11.5625 19.3987 12.625 21.2488 14.25 22.3239C13.2625 22.2989 12.3375 22.0239 11.525 21.5739C11.525 21.5989 11.525 21.6239 11.525 21.6489C11.525 24.5742 13.6 26.9994 16.3625 27.562C15.8625 27.6995 15.325 27.7745 14.775 27.7745C14.3875 27.7745 14.0125 27.737 13.6375 27.662C14.4 30.0622 16.625 31.7999 19.2625 31.8499C17.2 33.4625 14.6 34.4251 11.775 34.4251C11.2875 34.4251 10.8125 34.4001 10.3375 34.3376C12.975 36.0628 16.15 37.0629 19.55 37.0629Z"
      fill="#0C0B0A"
    />
  </svg>
</template>
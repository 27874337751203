<template>
  <svg
    width="44"
    height="48"
    viewBox="0 0 44 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="fast-forward-button-title"
    role="button"
    aria-label="Fast forward audio 20 seconds"
  >
    <title id="fast-forward-button-title">Fast forward audio 20 seconds</title>
    <path
      d="M21.4969 48C9.64345 48 0 38.3649 0 26.5294C0 15.7973 7.96715 6.78908 18.4331 5.29711L19.1927 0L29.8093 8.32493L17.2779 13.3399L17.9936 8.35001C9.39232 9.99869 2.94452 17.5588 2.94452 26.5294C2.94452 36.7476 11.2695 45.0599 21.5031 45.0599C31.7368 45.0599 40.0555 36.7476 40.0555 26.5294C40.0555 23.7837 39.4653 21.1383 38.3101 18.6684C37.9648 17.935 38.285 17.0573 39.0196 16.7125C39.7604 16.3678 40.6331 16.6875 40.9784 17.4209C42.322 20.2795 43.0063 23.3449 43.0063 26.5294C43 38.3649 33.3566 48 21.4969 48Z"
      fill="#EFE6DD"
    />
    <path
      d="M19.8956 31.984V30.528H14.5516L16.9516 28.448C18.9996 26.672 19.8156 25.504 19.8156 23.856C19.8156 21.632 18.3276 20.224 16.0076 20.224C13.6556 20.224 12.0876 21.84 12.0396 24.32H13.6076C13.6396 22.672 14.5676 21.632 15.9916 21.632C17.3836 21.632 18.2156 22.496 18.2156 23.952C18.2156 25.184 17.6876 25.968 15.8156 27.584L12.0876 30.8V32L19.8956 31.984ZM21.3387 26.224C21.3387 29.824 23.1147 32.208 26.1067 32.208C29.0827 32.208 30.8587 29.824 30.8587 26.224C30.8587 22.64 29.0027 20.208 26.1067 20.208C23.1947 20.208 21.3387 22.64 21.3387 26.224ZM22.8907 26.224C22.8907 23.344 24.0747 21.616 26.1067 21.616C28.1387 21.616 29.3067 23.344 29.3067 26.224C29.3067 29.072 28.1387 30.8 26.1067 30.8C24.0747 30.8 22.8907 29.072 22.8907 26.224Z"
      fill="#EFE6DD"
    />
  </svg>
</template>
<script>
export default {
  name: "FastForwardButton",
};
</script>
<template lang="">
  <div class="share-icons">
    <div class="share-icons__text mr-md">Share:</div>
    <a :href="twitterURL" class="mr-sm mt-sm" aria-label="Share on twitter">
      <twitter-logo />
    </a>
    <a :href="facebookURL" class="mr-sm mt-sm" aria-label="Share on Facebook">
      <facebook-logo />
    </a>
    <a href="https://www.instagram.com/raucouscaucous/" class="mr-sm mt-sm" aria-label="Share on Instagram">
      <instagram-logo />
    </a>
    <a :href="linkedinURL" class="mr-sm mt-sm" aria-label="Share on LinkedIn">
      <linked-in-logo />
    </a>
  </div>
</template>
<script>
import TwitterLogo from "./icons/TwitterLogo.vue";
import FacebookLogo from "./icons/FacebookLogo.vue";
import InstagramLogo from "./icons/InstagramLogo.vue";
import LinkedInLogo from "./icons/LinkedInLogo.vue";

export default {
  components: {
    TwitterLogo,
    FacebookLogo,
    InstagramLogo,
    LinkedInLogo,
  },
  data() {
    return {
      url: "www.theprickandthesting.org.uk",
      text: "An immersive audio drama fusing narrative, binaural sound and original music by Raucous.",
      facebookURL: "",
      twitterURL: "",
      linkedinURL: "",
    };
  },
  mounted() {
    this.generateURLs();
  },
  computed: {
    encodedURL() {
      return encodeURIComponent(this.url);
    },
    encodedText() {
      return encodeURIComponent(this.text);
    },
  },
  methods: {
    generateURLs() {
      this.facebookURL =
        "http://www.facebook.com/sharer/sharer.php?u=" +
        this.encodedURL +
        "&quote=" +
        this.encodedText;
      this.twitterURL =
        "https://twitter.com/intent/tweet?text=" +
        this.encodedText +
        "%40RaucousCaucous&url=" +
        this.encodedURL;
      this.linkedinURL =
        "https://www.linkedin.com/sharing/share-offsite/?url=" +
        this.encodedURL;
    },
  },
};
</script>
<style lang="scss" scoped>
.share-icons {
  display: flex;
  align-items: center;
}
</style>

<template>
  <div class="advance-btn p-md">
    <router-link :aria-label="buttonAria" class="advance-btn__link pr-lg pl-lg pt-md pb-md font-body" :class="{advanceBtnStopped : stopped}" :to="{ path: path }">{{
      title
    }}</router-link>
  </div>
</template>
<script>
export default {
  name: "AdvanceBtn",
  props: {
    title: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    stopped: {
      type: Boolean,
      required: true,
    },
    buttonAria: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.advance-btn {
  &__link {
    color: $primary-text;
    text-decoration: none;
    display: block;
    border: 1px solid $primary-text;
    border-radius: 2px;
    transition: color 1s, background-color 2s;
  }
}

</style>
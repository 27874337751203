<template>
  <a href="https://raucous.org.uk/" aria-label="Link to Raucous site">
    <svg
      width="90"
      height="57"
      viewBox="0 0 90 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.1765 54.7712H32.6657L29.59 0.110657H27.3931L30.5315 56.1306L31.4417 56.9842H36.4005L37.3107 56.1148L40.1981 0.61648L40.2138 0.110657H38.0168L35.1765 54.7712Z"
        fill="#EFE6DD"
      />
      <path
        d="M77.2636 0.110657H75.0509L72.2106 54.7712H69.6998L66.6398 0.110657H64.4272L67.5657 56.1306L68.4758 56.9842H73.4503L74.3448 56.1148L77.2322 0.61648L77.2636 0.110657Z"
        fill="#EFE6DD"
      />
      <path
        d="M23.2189 0.110657H18.9819L17.8364 1.16972L14.9647 56.4942L14.9333 56.9842H17.1459L19.9862 2.33944H22.1832L25.2432 56.9842H27.4558L24.3174 1.13811L23.2189 0.110657Z"
        fill="#EFE6DD"
      />
      <path
        d="M60.1118 1.20128L59.0133 0.173828H55.8434L54.7449 1.20128L50.2255 28.3893V28.6738L54.7606 56.0041L55.7806 56.9683H58.872L59.8764 56.0199L63.9564 28.7212V28.4367L60.1431 1.37516L60.1118 1.20128ZM57.852 54.7712H56.7693L52.4068 28.579L56.7693 2.38681H58.0717L61.7751 28.5632L57.852 54.7712Z"
        fill="#EFE6DD"
      />
      <path
        d="M12.1871 25.3702L12.6422 23.8844L9.83328 12.5824L7.04003 1.37522L5.78463 0.205505H1.89291L1.07691 0.948434L0.919983 1.1065L0.935675 56.9842H3.14831V2.43429L5.00001 2.41848C5.89448 5.97505 9.34681 19.9484 10.3511 23.9634L5.09417 28.1681L4.68616 29.5749L11.5281 56.9842H13.8348L6.97726 29.5433L12.1871 25.3702Z"
        fill="#EFE6DD"
      />
      <path
        d="M89.08 0.916819L85.5649 0.189697L79.2252 3.33529L78.7388 4.25209L85.0157 51.9892L76.8086 56.2887L80.2138 57H80.2295L86.8517 53.5225L87.3225 52.6215L81.0455 4.90018L89.08 0.916819Z"
        fill="#EFE6DD"
      />
      <path
        d="M44.6391 4.7421L52.5637 0.727121L49.0643 0L42.9286 3.12978L42.4421 3.93594V52.6373L42.8972 53.4118L49.0643 56.8735H49.1114L52.3754 56.1938L44.6391 51.8627V4.7421Z"
        fill="#EFE6DD"
      />
    </svg>
  </a>
</template>
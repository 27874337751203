<template>
  <main class="credits-page contained">
    <h1 class="dspl-none">Credits page</h1>
    <div class="credits-page__container container">
      <router-link class="router-link" :to="{ path: '/' }">
        <site-title class="credits-page__title" />
      </router-link>
      <div
        class="
          credits-page__standfirst
          left-highlight
          content
          mt-lg
          mb-lg
          font-big
        "
      >
        <p>
          Thank you for listening to The Prick & the Sting from
          <a href="https://raucous.org.uk/" class="raucous-link">Raucous</a>.
        </p>
      </div>
      <div class="credits-page__content content font-body">
        <p>
          We’d love to know more about your experience. Please leave your
          feedback and sign up to our newsletter. This is a prototype project
          and we hope to realise its full potential and create a live
          performance. If you are able to, please donate.
        </p>
      </div>
      <nav>
        <a
          class="
            credits-page__btn
            pt-md
            pb-md
            advanceBtnStopped
            mt-lg
            mb-md
            font-body
          "
          href="https://docs.google.com/forms/d/1GEadOV4vkbvRPJGOYdaqdx6MgSWwyqYs5Cnk3fL1CDc/edit?ts=623a0764"
          aria-label="Link to feedback form"
        >
          Feedback Form
        </a>
        <a
          class="
            credits-page__btn
            pt-md
            pb-md
            advanceBtnStopped
            mt-md
            mb-md
            font-body
          "
          href="http://raucous.org.uk/contact-us"
          aria-label="Link to Sign up for the raucous newsletter"
        >
          Newsletter Sign Up
        </a>
        <a
          class="
            credits-page__btn
            pt-md
            pb-md
            advanceBtnStopped
            mt-md
            mb-lg
            font-body
          "
          href="https://donorbox.org/prickandsting"
          aria-label="Link to donate on Donorbox"
        >
          Donate on Donorbox
        </a>
      </nav>
      <div class="credits-page__bottom-text left-highlight content font-small">
        <strong>Creatives</strong>
        <ul>
          <li>
            Writer & Director -
            <a
              href="https://raucous.org.uk/who-we-are"
              class="raucous-link"
              aria-label="Link to Sharon Clark's website"
              >Sharon Clark</a
            >
          </li>
          <li>
            Sound Artist -
            <a
              href="https://www.helenskiera.com"
              class="raucous-link"
              aria-label="Link to Helen Skiera's website"
              >Helen Skiera</a
            >
          </li>
          <li>
            Digital Technology Artist -
            <a
              href="https://jackhardiker.com/"
              class="raucous-link"
              aria-label="Link to Jack Hardiker's website"
              >Jack Hardiker</a
            >
          </li>
          <li>
            Composer -
            <a
              href="https://campsite.bio/northseanavigator"
              class="raucous-link"
              aria-label="Link to North Sea Navigator's website"
              >North Sea Navigator</a
            >
          </li>
        </ul>
        <strong>Cast</strong>
        <ul>
          <li>Tilly - Rona Morison</li>
          <li>Jesse - Jessica Clark</li>
          <li>William Bennet Brown - Christopher Bianchi</li>
          <li>Various Roles - Liam Murray Scott and Rose Skiera</li>
        </ul>
        <strong>Production Team</strong>
        <ul>
          <li>Producer - Harriet Bolwell</li>
          <li>Marketing - Claire Petrie & Jo Richardson</li>
          <li>Assistant Producer - Hannah Stratford</li>
          <li>
            Web Designer -
            <a
              href="https://jackhardiker.com/"
              class="raucous-link"
              aria-label="Link to Jack Hardiker's website"
              >Jack Hardiker</a
            >
          </li>
          <li>Illustrator - Sam Jones</li>
          <li>Access Consultant - VocalEyes</li>
          <li>Cellist - Anna-Helena Mclean</li>
          <li>Trailer Composition - Jack Offord</li>
          <li>
            Web developer -
            <a
              href="https://mattyjphelanportfolio.web.app/"
              class="raucous-link"
              aria-label="Link to Matthew J. Phelan's website"
              >Matthew J. Phelan</a
            >
          </li>
        </ul>
      </div>
      <div class="credits-page__content content pt-md">
        <p>
          Our heartfelt thanks to Arts Council England for making this project a
          reality by awarding Raucous funding through the Cultural Recovery
          Fund.
        </p>
      </div>
      <div class="credits-page__logos mt-lg mb-lg">
        <arts-council-england-logo class="mr-lg" />
        <raucous-logo class="ml-md" />
      </div>
      <share-icons class="mb-lg" />
    </div>
  </main>
</template>
<script>
import RaucousLogo from "../components/icons/RaucousLogo.vue";
import ArtsCouncilEnglandLogo from "../components/icons/ArtsCouncilEnglandLogo.vue";
import SiteTitle from "../components/SiteTitle.vue";
import ShareIcons from "../components/ShareIcons.vue";
export default {
  components: { SiteTitle, RaucousLogo, ArtsCouncilEnglandLogo, ShareIcons },
  name: "CreditsScreen",
};
</script>
<style lang="scss" scoped>
.credits-page {
  min-height: 100vh;
  &__btn {
    text-decoration: none;
    display: block;
    width: 100%;
    max-width: 650px;
  }
  &__logos {
    display: flex;
  }
}
ul {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
}
</style>
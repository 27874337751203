<template>
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.926 50.3569C39.7331 50.3569 50.9271 39.1641 50.9286 25.3569C50.93 11.5498 39.7383 0.356934 25.9312 0.356934C12.124 0.356934 0.929997 11.5498 0.928571 25.3569C0.927145 39.1641 12.1189 50.3569 25.926 50.3569Z"
      fill="#EFE6DD"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.0872 32.6639C22.0872 30.3175 22.074 27.9711 22.1005 25.6248C22.1005 25.2271 21.9945 25.1078 21.5972 25.121C20.8688 25.1476 20.1403 25.121 19.3987 25.1343C19.1073 25.1476 18.9881 25.0548 19.0014 24.7499C19.0146 23.2917 19.0146 21.8202 19.0014 20.362C19.0014 20.0836 19.0808 19.9908 19.3722 19.9908C20.1139 20.0041 20.8688 19.9643 21.6104 20.0041C22.0475 20.0306 22.127 19.8715 22.127 19.4739C22.1005 18.3603 22.1005 17.2468 22.127 16.1333C22.1535 14.7679 22.5243 13.4953 23.3322 12.395C24.352 10.9898 25.8088 10.3535 27.4908 10.274C29.2126 10.1944 30.9475 10.2342 32.6825 10.2209C32.9209 10.2209 33.0004 10.3005 33.0004 10.5391C32.9871 12.0238 32.9871 13.4953 33.0004 14.98C33.0004 15.2451 32.9209 15.3246 32.656 15.3246C31.8216 15.3114 30.9873 15.3246 30.1661 15.3246C29.0536 15.3246 28.3914 15.8946 28.3252 16.9949C28.2722 17.8698 28.312 18.758 28.2855 19.6462C28.2722 19.9643 28.4709 19.9378 28.6695 19.9378C29.9277 19.9378 31.1992 19.9511 32.4574 19.9246C32.8282 19.9246 32.9474 20.0041 32.9077 20.3885C32.7487 21.86 32.6163 23.3314 32.4838 24.8161C32.4574 25.1476 32.2984 25.2138 32.0071 25.2138C31.0138 25.2006 30.0072 25.2006 29.0139 25.2138C28.2987 25.2138 28.3649 25.1078 28.3649 25.8369C28.3649 30.4368 28.3517 35.0235 28.3782 39.6234C28.3782 40.1139 28.259 40.2332 27.7822 40.22C26.0737 40.1935 24.352 40.1935 22.6435 40.22C22.1667 40.2332 22.0872 40.0609 22.0872 39.6367C22.1005 37.2903 22.0872 34.9705 22.0872 32.6639Z"
      fill="#0C0B0A"
    />
  </svg>
</template>

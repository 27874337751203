<template>
  <div class="control-icons">
    <nav class="control-icons__nav container">
      <div class="control-icons__timer">{{ currentTime }} : {{ length }}</div>
      <div class="control-icons__buttons">
        <a
          class="control-icons__previous ml-md"
          @click="backwardBtnClick"
          ref="rewindButton"
        >
          <rewind-button />
        </a>
        <a
          v-if="playBtn"
          class="control-icons__play"
          ref="playButton"
          @click="playBtnClick"
          @keypress="playBtnClick"
          tabindex="0"
        >
          <play-pause
            :isPlaying="isPlaying"
            :finished="finished"
            :loading="loading"
            ref="playPause"
          />
        </a>
        <a
          class="control-icons__previous mr-md"
          @click="forwardBtnClick"
          ref="fastforwardButton"
        >
          <fast-forward-button />
        </a>
      </div>
      <div class="control-icons__advance mt-md">
        <advance-btn
          :title="buttonText"
          :path="advancePath"
          ref="nextBtn"
          :stopped="finished"
          :buttonAria="buttonAria"
        />
      </div>
    </nav>
  </div>
</template>
<script>
import AdvanceBtn from "./AdvanceBtn.vue";
import FastForwardButton from "./icons/FastForwardButton.vue";
import RewindButton from "./icons/RewindButton.vue";
import PlayPause from "./PlayPause.vue";

export default {
  name: "ControlIcons",
  components: {
    PlayPause,
    RewindButton,
    FastForwardButton,
    AdvanceBtn,
  },
  props: {
    advancePath: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonAria: {
      type: String,
      required: true,
    },
    playBtn: {
      type: Boolean,
      default: false,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    finished: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    length: {
      type: String,
      default: "00:00",
    },
    currentTime: {
      type: String,
      default: "00:00",
    },
  },
  data() {
    return {
      mobileSafari: false,
    };
  },
  methods: {
    playBtnClick: function () {
      this.$emit("toggle-audio", true);
    },
    backwardBtnClick: function () {
      this.$emit("backward-audio", true);
    },
    forwardBtnClick: function () {
      this.$emit("forward-audio", true);
    },
  },
};
</script>
<style lang="scss">
.control-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #151412;
  padding: 2rem 0;

  &__nav {
    width: 100%;
  }

  &__timer {
    margin-bottom: 2rem;
  }

  &__buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }

  align-items: center;
  &__previous {
    min-width: 55px;
  }
  &__next {
    min-width: 55px;
  }

  &__advance {
    width: 100%;
  }
}
</style>

<template>
  <div class="dressing-room">
    <index
      audioSrc="https://res.cloudinary.com/da5vwm34u/video/upload/v1648072449/DressingRoom_hlrkzt.mp3"
      page="dressing-room"
      advancePath="/final-piece"
      pageHeader="Dressing Room"
      buttonText="Start performance"
      buttonAria="Click to start the performance"
      :class="[{ 'dressing-room--main': main }]"
      @next-asset-dr="nextAsset"
    />
  </div>
</template>
<script>
import Index from "./Index.vue";
export default {
  name: "DressingRoom",
  components: {
    Index,
  },
  data() {
    return {
      main: false,
    };
  },
  methods: {
    nextAsset(index) {
      if (index == 3 || index == 0) {
        this.main = false;
      } else {
        this.main = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dressing-room {
  background-position: 50% 25%;
  background-repeat: no-repeat;
  background-size: 700px;
  background-image: radial-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 1)
    ),
    url("https://res.cloudinary.com/da5vwm34u/image/upload/v1648312621/3standby_hwglke.gif");
  @media (min-width: 860px) {
    background-size: 850px;
  }

  &--main {
    background-position: 50% 25%;
    background-repeat: no-repeat;
    background-size: 700px;
    background-image: radial-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 1)
      ),
      url("https://res.cloudinary.com/da5vwm34u/image/upload/v1648312628/3main_aql3tq.gif");
    @media (min-width: 860px) {
      background-size: 850px;
    }
  }
}
</style>
import { createApp, h, Fragment } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

let app = null

if (process.env.NODE_ENV === 'development') {
  const VueAxe = require('vue-axe')
  app = createApp({
    render: () => h(Fragment, [h(App), h(VueAxe.VueAxePopup)])
  })
  app.use(VueAxe.default)
} else {
  app = createApp(App)
}

const firebaseConfig = {
  apiKey: "AIzaSyDMDcpZ2Jrie61bCoDgB5kItNNH84DYk4g",
  authDomain: "theprickandthesting.firebaseapp.com",
  projectId: "theprickandthesting",
  storageBucket: "theprickandthesting.appspot.com",
  messagingSenderId: "940582584354",
  appId: "1:940582584354:web:2fd68696b5d0e336254a7b",
  measurementId: "G-CME9THPW0M"
};

firebase.initializeApp(firebaseConfig);
app.use(router);
app.use(VueGtag, {
  appName: 'theprickandthesting',
  pageTrackerScreenviewEnabled: true,
  config: { id: 'G-CME9THPW0M' }
}, router)
app.mount('#app')


<template>
  <div class="theatre-page">
    <index
      audioSrc="https://res.cloudinary.com/da5vwm34u/video/upload/v1648071692/Theatre_ijfqsa.mp3"
      page="theatre"
      advancePath="/stage-door"
      pageHeader="Theatre Page"
      buttonText="Next, pass the stage door"
      buttonAria="Click to pass through the stage door"
      :class="[{ 'theatre-page--main': main }]"
      @next-asset-theatre="nextAsset"
    />
  </div>
</template>
<script>
import Index from "./Index.vue";
export default {
  name: "Theatre",
  components: {
    Index,
  },
  data() {
    return {
      main: false,
    };
  },
  methods: {
    nextAsset(index) {
      if (index == 3 || index == 0) {
        this.main = false;
      } else {
        this.main = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.theatre-page {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 700px;
  background-image: radial-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.9),
        rgba(0, 0, 0, 1)
      ),
    url("https://res.cloudinary.com/da5vwm34u/image/upload/v1648312624/1start_seecsj.gif");
  @media (min-width: 860px) {
    background-size: 850px;
  }

  &--main {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 700px;
    background-image: radial-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.9),
        rgba(0, 0, 0, 1)
      ),
      url("https://res.cloudinary.com/da5vwm34u/image/upload/v1648312622/1main_ur04lc.gif");
    @media (min-width: 860px) {
      background-size: 850px;
    }
  }
}
</style>
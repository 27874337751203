<template>
  <main class="login-page contained pb-lg">
    <h1 class="dspl-none">The Prick and the Sting</h1>
    <div class="login-page__container">
      <site-title />
      <article class="left-highlight content mt-lg mb-lg">
        <p>
          The Prick & The Sting is an immersive audio experience fusing
          narrative, binaural sound, original music and technology by immersive
          theatre company, Raucous. The show has now closed. Please visit
          <a
            href="https://raucous.org.uk/"
            class="raucous-link"
            aria-label="Raucous - Link to the raucous website"
            >raucous.org.uk</a
          >
          for more info on the show or the company.
        </p>
      </article>
      <section class="login-page__form">
        <form action="#" @submit.prevent="submit">
          <div class="form-group row mb-md">
            <h2>Login</h2>
          </div>
          <div class="form-group row mb-md">
            <label for="email" class="col-md-4 col-form-label text-md-right"
              >Email</label
            >

            <div class="col-md-6">
              <input
                id="email"
                type="email"
                class="form-control mt-sm"
                name="email"
                value
                required
                autofocus
                v-model="form.email"
              />
            </div>
          </div>

          <div class="form-group row mb-lg">
            <label for="password" class="col-md-4 col-form-label text-md-right"
              >Password</label
            >

            <div class="col-md-6">
              <input
                id="password"
                type="password"
                class="form-control mt-sm"
                name="password"
                required
                v-model="form.password"
              />
            </div>
          </div>

          <div class="form-group row mb-0">
            <div class="col-md-8 offset-md-4">
              <button type="submit" class="btn btn-primary">Login</button>
            </div>
          </div>
        </form>
      </section>
      <raucous-logo />
    </div>
  </main>
</template>
<script>
import firebase from "firebase/compat/app";
import RaucousLogo from "../components/icons/RaucousLogo.vue";
import SiteTitle from "../components/SiteTitle.vue";
export default {
  components: { SiteTitle, RaucousLogo },
  name: "LoginScreen",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: null,
    };
  },
  methods: {
    submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(() => {
          console.log("Successfully logged in!");
          this.$router.push("/"); // redirect to the feed
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/invalid-email":
              this.error = "Invalid email";
              break;
            case "auth/user-not-found":
              this.error = "No account with that email was found";
              break;
            case "auth/wrong-password":
              this.error = "Incorrect password";
              break;
            default:
              this.error = "Email or password was incorrect";
              break;
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.login-page {
  min-height: 100vh;

  &__btn {
    text-decoration: none;
    display: block;
    width: 100%;
    max-width: 650px;
  }

  &__form {
    min-height: 60vh;
    display: flex;
    margin-top: 2rem;
  }

  &__container {
    max-width: 650px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.form-control {
  background-color: transparent;
  color: white;
  border-radius: 4px;
  border: 1px solid white;
  min-width: 360px;
  min-height: 36px;
  padding-left: 1rem;
}

.btn {
  background-color: white;
  color: black;
  border-radius: 4px;
  border: 1px solid white;
  min-width: 180px;
  min-height: 36px;
  padding-left: 1rem;
}
</style>
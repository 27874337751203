<template>
  <svg
    width="44"
    height="48"
    viewBox="0 0 44 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="rewind-button-title"
    aria-label="Rewind audio 20 seconds"
    role="button"
  >
    <title id="rewind-button-title">Rewind audio 20 seconds</title>
    <path
      d="M21.5095 48C33.3629 48 43.0063 38.3649 43.0063 26.5294C43.0063 15.7973 35.0392 6.78908 24.5733 5.29711L23.8136 0L13.197 8.32493L25.7285 13.3399L25.0128 8.35001C33.614 9.99869 40.0618 17.5588 40.0618 26.5294C40.0618 36.7476 31.7368 45.0599 21.5032 45.0599C11.2696 45.0599 2.95085 36.7476 2.95085 26.5294C2.95085 23.7837 3.54101 21.1383 4.69621 18.6684C5.04152 17.935 4.72133 17.0573 3.98677 16.7125C3.24593 16.3678 2.37325 16.6875 2.02794 17.4209C0.684383 20.2795 5.34058e-05 23.3449 5.34058e-05 26.5294C0.00632858 38.3649 9.64979 48 21.5095 48Z"
      fill="#EFE6DD"
    />
    <path
      d="M19.9019 31.984V30.528H14.5579L16.9579 28.448C19.0059 26.672 19.8219 25.504 19.8219 23.856C19.8219 21.632 18.3339 20.224 16.0139 20.224C13.6619 20.224 12.0939 21.84 12.0459 24.32H13.6139C13.6459 22.672 14.5739 21.632 15.9979 21.632C17.3899 21.632 18.2219 22.496 18.2219 23.952C18.2219 25.184 17.6939 25.968 15.8219 27.584L12.0939 30.8V32L19.9019 31.984ZM21.345 26.224C21.345 29.824 23.121 32.208 26.113 32.208C29.089 32.208 30.865 29.824 30.865 26.224C30.865 22.64 29.009 20.208 26.113 20.208C23.201 20.208 21.345 22.64 21.345 26.224ZM22.897 26.224C22.897 23.344 24.081 21.616 26.113 21.616C28.145 21.616 29.313 23.344 29.313 26.224C29.313 29.072 28.145 30.8 26.113 30.8C24.081 30.8 22.897 29.072 22.897 26.224Z"
      fill="#EFE6DD"
    />
  </svg>
</template>
<script>
export default {
  name: "RewindButton",
};
</script>
<template>
  <div v-if="loading">
    <svg
      style="
        margin: auto;
        background: none;
        display: block;
        shape-rendering: auto;
        padding-bottom: 8px;
      "
      width="80px"
      height="80px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      aria-labelledby="play-button-loading"
      ref="loadingSpinner"
      role="button"
      tabindex="0"
    >
      <title id="play-button-loading">The media is loading.</title>
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#efe6dd"
        stroke-width="3"
        r="35"
        stroke-dasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  </div>
  <div v-else>
    <div v-if="!isPlaying" class="play-button">
      <svg
        width="80"
        height="81"
        viewBox="0 0 80 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="play-button-title"
        ref="playButton"
        role="button"
        tabindex="0"
      >
        <title id="play-button-title">
          Media Loaded. Play button: click to start and stop audio
        </title>
        <circle
          cx="40"
          cy="40.5"
          r="39.5"
          :fill="[finished ? '#0C0B0A' : '#EFE6DD']"
          stroke="#EFE6DD"
        />
        <path
          d="M55 41L32.5 53.9904L32.5 28.0096L55 41Z"
          :fill="[finished ? '#EFE6DD' : '#0C0B0A']"
        />
      </svg>
    </div>
    <div v-else class="pause-button">
      <svg
        width="80"
        height="81"
        viewBox="0 0 80 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="pause-button-title"
        role="button"
        tabindex="0"
      >
        <title id="pause-button-title">Pause button: click to stop audio</title>
        <circle
          cx="40"
          cy="40.5"
          r="39.5"
          fill="black"
          fill-opacity="0.7"
          stroke="#EFE6DD"
        />
        <path
          d="M33.68 51.5599C35.418 51.5599 36.84 50.1379 36.84 48.3999V32.5999C36.84 30.8619 35.418 29.4399 33.68 29.4399C31.942 29.4399 30.52 30.8619 30.52 32.5999V48.3999C30.52 50.1379 31.942 51.5599 33.68 51.5599ZM43.16 32.5999V48.3999C43.16 50.1379 44.582 51.5599 46.32 51.5599C48.058 51.5599 49.48 50.1379 49.48 48.3999V32.5999C49.48 30.8619 48.058 29.4399 46.32 29.4399C44.582 29.4399 43.16 30.8619 43.16 32.5999Z"
          fill="#EFE6DD"
        />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: "PlayPause",
  props: {
    isPlaying: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    finished: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="">
</style>
<template>
<div class="stage-door">
  <index
    audioSrc="https://res.cloudinary.com/da5vwm34u/video/upload/v1648072449/StageDoor_mc6ns5.mp3"
    page="stage-door"
    advancePath="/dressing-room"
    pageHeader="Stage Door"
    buttonText="Next, enter the dressing room"
    buttonAria="Click to enter the dressing room"
    :class="[{ 'stage-door--main': main }]"
    @next-asset-sd="nextAsset"
  />
  </div>
</template>
<script>
import Index from "./Index.vue";
export default {
  name: "StageDoor",
  components: {
    Index,
  },
  data() {
    return {
      main: false,
    };
  },
  methods: {
    nextAsset(index) {
      if (index == 3 || index == 0) {
        this.main = false;
      } else {
        this.main = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.stage-door {
  background-position: 50% 20%;
  background-repeat: no-repeat;
  background-size: 700px;
  background-image: radial-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.9),
        rgba(0, 0, 0, 1)
      ),
    url("https://res.cloudinary.com/da5vwm34u/image/upload/v1648312622/2start_nlptio.gif");
  @media (min-width: 860px) {
    background-size: 850px;
  }

  &--main {
    width: 100%;
    height: 100%;
    background-position: 50% 20%;
    background-repeat: no-repeat;
    background-size: 700px;
    background-image: radial-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.9),
        rgba(0, 0, 0, 1)
      ),
      url("https://res.cloudinary.com/da5vwm34u/image/upload/v1648312628/2main_xz8vka.gif");
    @media (min-width: 860px) {
      background-size: 850px;
    }
  }
}
</style>
<template>
  <index
    audioSrc="https://res.cloudinary.com/da5vwm34u/video/upload/v1648072505/FinalPiece_bxr9np.mp3"
    page= "final-piece"
    advancePath="/credits-page"
    pageHeader="Final Piece"
    buttonText= "Leave the theatre"
    buttonAria="Click to leave the theatre"
    :class="[`final-piece--${index}`, 'final-piece']"
  />
</template>
<script>
import Index from "./Index.vue";
export default {
  name: "FinalPiece",
  components: {
    Index,
  },
};
</script>
<style lang="scss" scoped>

</style>
<template>
  <main
    :class="[
      { 'index-screen--mobile': mobileSafari },
      'index-screen',
      'screen',
    ]"
  >
    <audio ref="audio" preload id="audio">
      <source v-bind:src="audioSrc" type="audio/mpeg" />
    </audio>
    <site-title class="index-screen__title" />
    <h1 class="dspl-none">{{ pageHeader }}</h1>
    <control-icons
      class="toggle-sound paused"
      :length="length"
      :currentTime="currentTime"
      :playBtn="true"
      :isPlaying="isPlaying"
      :advancePath="advancePath"
      :buttonText="buttonText"
      :buttonAria="buttonAria"
      :finished="finished"
      :loading="loading"
      @toggle-audio="toggleAudio"
      @backward-audio="backwardAudio"
      @forward-audio="forwardAudio"
      ref="controlIcons"
    />
  </main>
</template>
<script>
import ControlIcons from "../components/ControlIcons.vue";
import SiteTitle from "../components/SiteTitle.vue";
export default {
  name: "IndexPage",
  components: {
    ControlIcons,
    SiteTitle,
  },
  props: {
    audioSrc: {
      type: String,
      required: true,
    },
    advancePath: {
      type: String,
      required: true,
    },
    pageHeader: {
      type: String,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonAria: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      audio: undefined,
      isPlaying: false,
      finished: false,
      currentTime: "00:00",
      length: "00:00",
      loading: true,
      mobileSafari: false,
    };
  },
  methods: {
    getMinutesAndSecondsFromDuration(duration) {
      var seconds = duration;
      var minutes = Math.floor(seconds / 60);
      minutes = minutes >= 10 ? minutes : "0" + minutes;
      seconds = Math.floor(seconds % 60);
      seconds = seconds >= 10 ? seconds : "0" + seconds;

      return `${minutes}:${seconds}`;
    },
    findNextAsset() {
      if (this.page == "theatre") {
        if (this.audio.currentTime > 8.5 && this.audio.currentTime < 9.5) {
          this.$emit("next-asset-theatre", 1);
          setTimeout(() => {
            this.$emit("next-asset-theatre", 3);
          }, 25000);
        }
      } else if (this.page == "stage-door") {
        if (this.audio.currentTime > 11.5 && this.audio.currentTime < 12.5) {
          this.$emit("next-asset-sd", 1);
          setTimeout(() => {
            this.$emit("next-asset-sd", 3);
          }, 17000);
        }
      } else if (this.page == "dressing-room") {
        if (this.audio.currentTime > 20 && this.audio.currentTime < 21) {
          this.$emit("next-asset-dr", 1);
          setTimeout(() => {
            this.$emit("next-asset-dr", 3);
          }, 35000);
        }
      }
    },
    playbackListener() {
      this.currentTime = this.getMinutesAndSecondsFromDuration(
        this.audio.currentTime
      );
      this.findNextAsset();
      this.audio.addEventListener("ended", this.endListener);
    },
    load() {
      if (this.audio.readyState >= 2) {
        this.audio.pause();
        this.loaded = true;
        this.length = this.getMinutesAndSecondsFromDuration(
          this.audio.duration
        );
        this.loading = false;
        if (this.page !== "theatre") {
          this.toggleAudio();
        }
      }
    },
    endListener() {
      this.isPlaying = false;
      this.listenerActive = false;
      // this.findEndAsset();
      this.cleanupListeners();
      this.finished = true;
      this.focusOnNext();
    },
    focusOnNext() {
      this.$nextTick(() => {
        const nextBtnRef =
          this.$refs.controlIcons.$refs.nextBtn.$el.children[0];
        nextBtnRef.focus();
      });
    },
    cleanupListeners() {
      var audio = this.audio;
      audio.removeEventListener("ended", this.endListener);
      audio.removeEventListener("timeupdate", this.playbackListener);
    },
    toggleAudio() {
      var audio = this.audio;
      if (audio.paused) {
        audio.play();
        this.isPlaying = true;
      } else {
        audio.pause();
        this.isPlaying = false;
      }
    },
    backwardAudio() {
      this.audio.currentTime = this.audio.currentTime - 20;
    },
    forwardAudio() {
      this.audio.currentTime = this.audio.currentTime + 20;
    },
    checkBrowserMobileSafari: function () {
      var ua = window.navigator.userAgent;
      var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
      var webkit = !!ua.match(/WebKit/i);
      var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

      if (iOSSafari) {
        this.mobileSafari = true;
      }
    },
  },
  mounted: function () {
    this.checkBrowserMobileSafari();
    this.audio = this.$refs.audio;
    this.$nextTick(function () {
      this.$watch("isPlaying", function () {
        if (this.isPlaying) {
          if (!this.listenerActive) {
            this.listenerActive = true;
            this.audio.addEventListener("timeupdate", this.playbackListener);
          }
        }
      });
    });
    this.audio.autoplay = true;
    this.audio.addEventListener("loadeddata", this.load);
  },
  beforeUnmount() {
    this.audio.pause();
    this.cleanupListeners();
  },
};
</script>
<style lang="scss">
.index-screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  align-items: center;

  &--mobile {
    height: 100vh;
  }

  &__title {
    margin-top: 6rem;
  }
}

.final-piece {
  > .site-title {
    position: absolute;
    top: calc(50% - 180px);
    left: calc(50% - 72px);
    margin: 0;
  }
}
</style>
<template>
  <main class="splash-page contained">
    <h1 class="dspl-none">The Prick and the Sting</h1>
    <div class="splash-page__container container">
      <site-title />
      <article
        class="
          splash-page__standfirst
          left-highlight
          content
          mt-lg
          mb-lg
          font-big
        "
      >
        <p>
          An immersive audio experience fusing narrative, binaural sound and
          original music by
          <a
            href="https://raucous.org.uk/"
            class="raucous-link"
            aria-label="Raucous - Link to the raucous website"
            >Raucous</a
          >.
        </p>
      </article>
      <article class="splash-page__content content font-body" id="main">
        <p>
          Inside a broken down old theatre, in the century before the century
          before last, we find Jesse Byrne - actress, terrible singer, gambler,
          maverick and her apprentice, Tilly. They are at the tail-end of a
          gruelling theatre tour making the money they need to take them to a
          new life. But desperation has many faces and sometimes what we want
          isn’t always as shiny as it promises…
        </p>
        <p>
          <strong>You will need stereo headphones</strong> as this is a story
          told through binaural sound, akin to 3D sound. This experience is
          <strong>free</strong>
          and lasts 30 minutes. It has strong language and adult themes
          throughout, therefore it may not be suitable for everyone.
        </p>
        <p>
          The Prick & The Sting has a three part audio prologue that introduces
          the world and characters…. and then Jesse’s story begins.
        </p>
      </article>
      <nav>
        <router-link
          class="
            splash-page__btn
            pt-md
            pb-md
            advanceBtnStopped
            mt-lg
            mb-lg
            font-body
          "
          :to="{ path: '/theatre' }"
          aria-label="Click to enter The Prick and The Sting"
        >
          Enter The Prick & The Sting
        </router-link>
      </nav>
      <article
        class="splash-page__bottom-text left-highlight content font-small mb-lg"
      >
        <p>
          <a
            href="https://raucous.org.uk/"
            class="raucous-link"
            aria-label="Raucous - Link to the raucous website"
            >Raucous</a
          >
          is a collective of theatre makers, technologists, scientists and
          designers who harness different creative practices to build stories
          that are vibrant, vital and visceral, creating theatre that has
          digital technology sewn into its narrative fabric, underscored by
          original music, film and performance.
        </p>
        <raucous-logo />
      </article>
    </div>
  </main>
</template>
<script>
import RaucousLogo from "../components/icons/RaucousLogo.vue";
import SiteTitle from "../components/SiteTitle.vue";
export default {
  components: { SiteTitle, RaucousLogo },
  name: "SplashScreen",
};
</script>
<style lang="scss" scoped>
.splash-page {
  min-height: 100vh;

  &__btn {
    text-decoration: none;
    display: block;
    width: 100%;
    max-width: 650px;
  }
}
</style>
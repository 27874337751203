import { createWebHistory, createRouter } from "vue-router";
import Splash from "@/views/Splash.vue";
import Login from "@/views/Login.vue";
import CreditsPage from "@/views/CreditsPage.vue";
import Theatre from "@/views/Theatre.vue";
import StageDoor from "@/views/StageDoor.vue";
import DressingRoom from "@/views/DressingRoom.vue";
import FinalPiece from "@/views/FinalPiece.vue";
import firebase from 'firebase/compat/app';

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: 'Login | The Prick & The Sting'
    }
  },
  {
    path: "/",
    name: "Splash",
    component: Splash,
    meta: {
      title: 'Home | The Prick & The Sting'
    }
  },
  {
    path: "/theatre",
    name: "Theatre",
    component: Theatre,
    meta: {
      title: 'Theatre | The Prick & The Sting'
    }
  },
  {
    path: "/stage-door",
    name: "StageDoor",
    component: StageDoor,
    meta: {
      title: 'StageDoor | The Prick & The Sting'
    }
  },
  {
    path: "/final-piece",
    name: "FinalPiece",
    component: FinalPiece,
    meta: {
      title: 'Final Piece | The Prick & The Sting'
    }
  },
  {
    path: "/credits-page",
    name: "CreditsPage",
    component: CreditsPage,
    meta: {
      title: 'Credits | The Prick & The Sting'
    }
  },
  {
    path: "/dressing-room",
    name: "Dressing Room",
    component: DressingRoom,
    meta: {
      title: 'Dressing Room | The Prick & The Sting'
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login') {
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) { // not logged in
        router.push('/login')
      } else {
        document.title = to.meta.title
        next()
      }
    })
  } else {
    document.title = to.meta.title
    next()
  }
})

export default router;
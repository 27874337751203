<template>
  <svg
    width="50"
    height="51"
    viewBox="0 0 50 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 50.1269C38.8071 50.1269 50 38.9329 50 25.1244C50 11.3159 38.8071 0.121826 25 0.121826C11.1929 0.121826 0 11.3159 0 25.1244C0 38.9329 11.1929 50.1269 25 50.1269Z"
      fill="#EFE6DD"
    />
    <path
      d="M31.6131 42H17.3944C12.2185 42 8 37.6614 8 32.3209V17.6791C8 12.3463 12.211 8 17.3944 8H31.6056C36.7815 8 41 12.3386 41 17.6791V32.3209C41 37.6614 36.789 42 31.6131 42ZM17.3944 10.9207C13.7743 10.9207 10.8348 13.9569 10.8348 17.6791V32.3209C10.8348 36.0508 13.7817 39.0793 17.3944 39.0793H31.6056C35.2257 39.0793 38.1652 36.0431 38.1652 32.3209V17.6791C38.1652 13.9492 35.2183 10.9207 31.6056 10.9207H17.3944Z"
      fill="#0C0B0A"
    />
    <path
      d="M24.5 34C19.2624 34 15 29.9619 15 25C15 20.0381 19.2624 16 24.5 16C29.7376 16 34 20.0381 34 25C33.9916 29.9619 29.7292 34 24.5 34ZM24.5 19.0186C21.0195 19.0186 18.1863 21.7027 18.1863 25C18.1863 28.2973 21.0195 30.9814 24.5 30.9814C27.9805 30.9814 30.8137 28.2973 30.8137 25C30.8137 21.7027 27.9805 19.0186 24.5 19.0186Z"
      fill="#0C0B0A"
    />
    <path
      d="M36 15.5046C36 16.8858 35.1013 18 34.0037 18C32.8987 18 32 16.8766 32 15.5046C32 14.1234 32.8987 13 34.0037 13C35.1087 13.0092 36 14.1234 36 15.5046Z"
      fill="#0C0B0A"
    />
  </svg>

</template>
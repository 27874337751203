<template>
  <svg
    width="50"
    height="51"
    viewBox="0 0 50 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 50.0051C38.8071 50.0051 50 38.8111 50 25.0026C50 11.194 38.8071 0 25 0C11.1929 0 0 11.194 0 25.0026C0 38.8111 11.1929 50.0051 25 50.0051Z"
      fill="#EFE6DD"
    />
    <g clip-path="url(#clip0_216_27)">
      <path
        d="M18.8872 13.8266C18.8872 15.3861 17.7579 16.6532 15.8835 16.6532C14.1294 16.6289 13 15.3861 13 13.8266C13 12.2184 14.1774 11 15.9556 11C17.7579 11 18.8392 12.2184 18.8872 13.8266ZM13.1442 37V18.8707H18.695V37H13.1442Z"
        fill="#0C0B0A"
      />
      <path
        d="M22.0352 24.6456C22.0352 22.3794 21.9631 20.5032 21.891 18.8705H26.7209L26.9853 21.3804H27.1054C27.8263 20.1864 29.6285 18.4563 32.6322 18.4563C36.2847 18.4563 39.0241 20.9418 39.0241 26.2782V36.9999H33.4492V26.9361C33.4492 24.5969 32.6562 23.013 30.6378 23.013C29.0999 23.013 28.1867 24.0852 27.7782 25.133C27.6341 25.4985 27.586 26.0346 27.586 26.5463V36.9999H22.0352V24.6456Z"
        fill="#0C0B0A"
      />
    </g>
    <defs>
      <clipPath id="clip0_216_27">
        <rect
          width="26"
          height="26"
          fill="white"
          transform="translate(13 11)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
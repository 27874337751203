<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
#app {
  font-family: $primary-font;
  text-align: center;
  color: $primary-text;
  font-size: $font-normal;
  background-color: black;
}
p {
  padding-top: 4px;
}
</style>
